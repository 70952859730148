<template>
  <SchedulePage />
</template>

<script>
  import SchedulePage from '@/pages/schedules/SchedulePage';

  export default {
    components: {
      SchedulePage
    },
    name: 'CreateSchedule'
  };
</script>